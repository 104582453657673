import * as React from "react"
import Website from "@src/components/wrappers/website"
import Footer from "@components/marketing/footer"
import Nav from "@src/components/marketing/headerAlt"
import { Link } from "gatsby"

const seo = {
    url: "/privacy",
    title: "Privacy Policy • Sheetsync",
    description: "Sheetsync Privacy Policy"
}

export default class Privacy extends React.Component {
    render() {
        return (
            <Website seo={seo} marketing>
                <div className="bg-white">
                    <Nav />
                    <div className="text-center px-6 py-10 sm:py-12 md:py-16">
                        <h1 className="mx-auto text-gray-900 font-semibold leading-tight text-3xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-5xl lg:max-w-4xl">
                            Sheetsync Privacy Policy
    		    </h1>
                    </div>
                </div>


                <div className="max-w-2xl mx-auto pb-12 px-6">
                    <p>Last updated on September 24, 2020.</p>
                    <br />
                    <ol className="list-decimal pl-4">
                        <li className="pt-1">
                            <Link to="/privacy#section-1" className="font-semibold text-blue-800 hover:underline">What does this policy cover?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-2" className="font-semibold text-blue-800 hover:underline">What does this policy not cover?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-3" className="font-semibold text-blue-800 hover:underline">What information do you collect about me?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-4" className="font-semibold text-blue-800 hover:underline">How will you use the data you collect about me?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-5" className="font-semibold text-blue-800 hover:underline">Do you share my data with other organizations?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-6" className="font-semibold text-blue-800 hover:underline">How long will you keep my information?</Link>
                        </li>
                        <li className="pt-1">
                            <Link to="/privacy#section-7" className="font-semibold text-blue-800 hover:underline">Who can I get in touch with if I want to access my data?</Link>
                        </li>
                    </ol>
                    <div id="section-1" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">What does this policy cover?</p>
                        <p>This policy covers the data Sheetsync, LLC (“we”) collect about you (“user”), what we do with it, why we collect it, and how you can access it. This policy also covers both the logged-in application ("App") and the public marketing site ("Marketing Site").</p>
                    </div>
                    <div id="section-2" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">What does this policy not cover?</p>
                        <p>This policy does not cover what any third party services do with your data. We employ the services of trusted companies to do things like connect to your banks or collect your billing information. Examples of third-party services we use and the reasons are:</p>
                        <ul className="list-disc pl-12 pt-6">
                            <li className="pt-1">
                                <span>Google Sheets </span>
                                <a href="https://policies.google.com/privacy?hl=en" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - spreadsheet hosting</span>
                            </li>
                            <li className="pt-1">
                                <span>Stripe </span>
                                <a href="https://stripe.com/privacy" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - collect billing information and process payments</span>
                            </li>
                            <li className="pt-1">
                                <span>Plaid </span>
                                <a href="https://plaid.com/legal/#end-user-privacy-policy" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - connect to your banks</span>
                            </li>
                            <li className="pt-1">
                                <span>Postmark </span>
                                <a href="https://wildbit.com/privacy-policy" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - send you emails</span>
                            </li>
                            <li className="pt-1">
                                <span>Google Analytics </span>
                                <a href="https://policies.google.com/privacy?hl=en" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - user analytics</span>
                            </li>
                            <li className="pt-1">
                                <span>Mixpanel </span>
                                <a href="https://mixpanel.com/legal/privacy-policy/" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - product analytics</span>
                            </li>
                            <li className="pt-1">
                                <span>Sentry </span>
                                <a href="https://sentry.io/privacy/" className="font-semibold text-blue-800 hover:underline">(Privacy Policy)</a>
                                <span> - error tracking and debugging</span>
                            </li>
                        </ul>
                    </div>
                    <div id="section-3" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">What information do you collect about me?</p>
                        <p className="font-semibold text-xl">Information you provide us</p>
                        <ul className="list-disc pl-12 pt-4">
                            <li>
                                <span className="font-semibold">Google account information: </span>
                                When you sign up, Google provides us with the name and email address associated with your Google account. When you share access to your Google Sheets, Google provides us with a token to read and write data to only spreadsheets created by Sheetsync.
                    </li>
                            <li className="pt-4">
                                <span className="font-semibold">Billing details: </span>
                                You may provide us with your billing details. This information never goes through our server and we do not store it; we send this directly and securely to a trusted third-party service listed above to process the data for us.
                    </li>
                            <li className="pt-4">
                                <span className="font-semibold">Financial details: </span>
                                When you link to your bank via Sheetsync, we are able to pull information about your accounts, transactions, and balances. We do not have access to your personal bank credentials. We only have read-only access to your account, and we are not able to make any changes to your bank accounts.
                    </li>
                        </ul>
                        <p className="font-semibold text-xl mt-4">Information collected via technology</p>
                        <ul className="list-disc pl-12 pt-4">

                            <li>
                                <span className="font-semibold">Usage data: </span>
                                As you use the marketing site and app, we have analytics tools that will collect information about how you are using the site and information about your device, browser, and IP address.
                    </li>
                            <li className="pt-4">
                                <span className="font-semibold">Email tracking pixels: </span>
                                We will periodically send you emails as you engage with our product. These emails contain a tracking pixel which will let us know if you have opened the email. We use this information to analyze how our users are responding to our communication efforts and to ultimately improve the service for you.
                    </li>
                        </ul>
                    </div>
                    <div id="section-4" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">How will you use the data you collect about me?</p>
                        <p>In general, we collect information about you in order to operate our product and provide you the services outlined throughout the Marketing Site. This includes, but is not limited to:</p>
                        <ul className="list-disc pl-12 pt-6 pb-6">
                            <li className="pt-1">
                                displaying a list of your financial accounts,
                    </li>
                            <li className="pt-1">
                                displaying a list of your spreadsheets,
                    </li>
                            <li className="pt-1">
                                inserting your financial data into your spreadsheets,
                    </li>
                            <li className="pt-1">
                                sending you relevant information, such as updates, notifications, and support messages.
                    </li>
                        </ul>
                        <p className="font-semibold">We will only access your spreadsheets if you explicitly give us permission during a support request.</p>
                    </div>
                    <div id="section-5" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Do you share my data with other organizations?</p>
                        <p>We share select information with the third-party services listed above, but strictly only for the necessary operations of the service. <span className="font-semibold">We do not sell your information and we’re committed to never doing this.</span> We make money from your subscription only - you will never see ads on our platform.</p>
                    </div>
                    <div id="section-6" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">How long will you keep my information?</p>
                        <p>We will keep your information as long as you are a user (either trialing or paid) of the service. If you request a cancellation of your account either by email via <a className="text-blue-800 hover:underline" href="mailto:support@getsheetsync.com">support@getsheetsync.com</a> or through the product, we will delete your account and all associated data within 72 hours.</p>
                    </div>
                    <div id="section-7" className="pt-12">
                        <p className="font-semibold text-2xl mb-4">Who can I get in touch with if I want to access my data?</p>
                        <p>You can access your financial data by using the product. If you would like an export of all the data we have on you, please contact us at <a className="text-blue-800 hover:underline" href="mailto:support@getsheetsync.com">support@getsheetsync.com</a>.</p>
                    </div>
                </div>
                <Footer />
            </Website>
        )
    }
}